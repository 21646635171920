// export const MAIN_URL = "http://192.168.29.238:8000/";

export const BUCKET_URL = "https://gamersaimstorage.s3.ap-south-1.amazonaws.com";

export const MAIN_URL = "https://api.alleygames.in/";
export const BASE_URL = MAIN_URL;
export const ALL_GAME = BASE_URL + "allgames/";
export const ALL_CATEGORIES = BASE_URL + "categories/";
export const CONTACT_US = BASE_URL + "contactus/";
export const GAME_BY_CAT_ID = BASE_URL + "category/";
export const GAME_BY_UUID = BASE_URL + "game/";
export const ALL_BLOG = BASE_URL + "blogs/";
export const SINGLE_BLOG = BASE_URL + "blog/";
export const SEARCH_GAME = BASE_URL + "games_search/?q=";
// export const GAMES_BY_CATEGORY = BASE_URL + "games-by-category/";
export const GAMES_BY_SECTION = BASE_URL + "gamesections/";
export const GET_ABOUT_US = BASE_URL + "about_us/";
export const GET_PRIVACY_POLICY = BASE_URL + "policy/";
export const GET_SOCIAL_LINK = BASE_URL + "socialmedia/";
export const GET_ADS_MANAGER = BASE_URL + "active_ad/";